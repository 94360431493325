import { graphql, Link, PageProps } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../../components/Layout";
import Seo from "../../components/seo";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import routes from "../../routes";

export default ({
  data: {
    mdx: {
      body,
      frontmatter: { date, title, author, image, image_alt },
    },
  },
}: PageProps<IData>) => {
  const imageData = getImage(image);

  return (
    <Layout>
      <Seo title={title} />
      <div className="container mb-5 single-blog-post">
        <div className="col-12">
          <p className="breadcrumb">
            <Link to={routes.blog}>Blog</Link>&nbsp;/&nbsp;
            {title}
          </p>
          <div className="row">
            <div className="col-md-6 offset-md-4">
              <h1>{title}</h1>
              <p className="mt-3 mb-md-4">
                <i>
                  Updated {date} by {author}
                </i>
              </p>
            </div>
          </div>
          <article className="row blog-post">
            <div className="feature-image-wrapper offset-md-1 col-md-3 mb-3">
              <GatsbyImage image={imageData} alt={image_alt ?? ""} />
            </div>
            <div className="col-md-7">
              <MDXRenderer>{body}</MDXRenderer>
            </div>
          </article>
        </div>
      </div>
    </Layout>
  );
};

interface IData {
  mdx: {
    body: string;
    frontmatter: {
      date: string;
      title: string;
      author: string;
      image: ImageDataLike;
      image_alt: string;
    };
  };
}

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        date(formatString: "DD MMMM YYYY", locale: "en")
        title
        author
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        image_alt
      }
    }
  }
`;
